<template>
<div>
 <v-col cols="12">
        <h4>Actualización cada 1 hora. Inicio 09:00 am - Fin: 18:00 pm</h4>
    </v-col>
    <v-col cols="12">
        <v-row>
            <v-col cols="12" lg="12" md="12">
                <iframe title="Reporte Casos Legales" width="1000" height="541.25" :src="src" frameborder="0" allowFullScreen="true"></iframe>
            </v-col>
        </v-row>
    </v-col>
</div>
</template>

<script>
import _sGenParam from "@/services/General/ParameterService";
export default {
    name: "",
    components: {
        
    },
    props: {},
    data() {
        return {
            itemsType: [],
            headerType: [],
            src: ""

        }
    },
    created() {
        this.loadParameter()
    },

    methods: {

        loadParameter() {
            _sGenParam
                .search({
                    PrmName: "srcContratosBI"
                }, this.$fun.getUserID())
                .then((resp) => {
                    if (resp.status == 200) {
                        this.src = resp.data.PrmValue;
                    }
                });
        },

    },

};
</script>
